import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// import material ui
import { Typography } from "@material-ui/core"

// import app components
import SidebarContent from "components/sidebarContent"
import { getUrlPath } from "utils/getUrlPath"

import Boxes from "../../../components/flexibleContent/Boxes"

const Videos = (props) => {
  const {
    data: {
      wp: {
        themeSettings: {
          siteOptions: { defaultHeroImage }
        }
      },
      wpPage: { title, uri },
      allWpVideoCategory
    }
  } = props

  return (
    <Container>
      <SidebarContent image={defaultHeroImage} title={title} slug={getUrlPath(uri)} hideSidebar={true} edgesSize={"md"}>
        {allWpVideoCategory.nodes &&
          allWpVideoCategory.nodes.map((o) => {
            let youtube_id
            let youtube_img
            let imageObject

            let currentBox = o.contentNodes.nodes.map((o) => {
              if (!o.Video.image && o.Video.url?.includes("youtu")) {
                youtube_id = o.Video.url.split("/").pop()
                youtube_img = `https://img.youtube.com/vi/${youtube_id}/0.jpg`

                // Generate Gatsby image markup
                imageObject = {
                  localFile: {
                    childImageSharp: {
                      gatsbyImageData: {
                        height: 360,
                        width: 480,
                        images: {
                          fallback: {
                            sizes: "(max-width: 300px) 100vw, 300pxw" || "",
                            srcSet: `${youtube_img} 75w` || "",
                            src: youtube_img
                          },
                          sources: [
                            {
                              sizes: "(max-width: 300px) 100vw, 300pxw" || "",
                              srcSet: `${youtube_img} 75w` || "",
                              type: "image"
                            }
                          ]
                        },
                        layout: "constrained",
                        placeholder: { fallback: "" }
                      },
                      alt: o.title
                    }
                  }
                }
              } else {
                imageObject = o.Video.image
              }

              return {
                image: imageObject,
                link: { url: getUrlPath(o.uri) },
                text: "",
                headline: o.title
              }
            })
            if (currentBox.length > 0) {
              return (
                <>
                  <Typography variant="h2">{o.name}</Typography>
                  <Boxes boxes={currentBox} hideSidebar={true} buttonVisibility={true} />
                </>
              )
            } else {
              return
            }
          })}
      </SidebarContent>
    </Container>
  )
}

const Container = styled.div`
  .MuiTypography-h3 {
    text-transform: revert;
  }
`

export const CollectionQuery = graphql`
  query VideosTemplate($id: String!) {
    allWpVideoCategory {
      nodes {
        name
        contentNodes {
          nodes {
            ... on WpVideo {
              title
              uri
              Video {
                description
                url
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(quality: 100, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acfModules {
        hideSidebar
        hideSearch
      }
    }
    wp {
      themeSettings {
        siteOptions {
          defaultHeroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

export default Videos
